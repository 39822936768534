.triangle {
    position: fixed;     /* So it stays in bottom-right corner of screen */
    bottom: 0;
    right: 0;
    
    width: 0;
    height: 0;
    /* This is the exact same 150px dimension we "cut out" in the BlackContainer. */
    border-left: 150px solid transparent;
    border-bottom: 150px solid black;
    z-index: 10000;  /* above the container's overlay, so it can cover that “hole” */
  }
  