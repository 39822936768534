.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: black;
    /* 
      We cut out a triangle in the bottom-right corner so that area is transparent.
      That “hole” must match the same 150px side used in the black triangle 
      so they align perfectly. 
      The clip-path below is just an example shape:
    */
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 150px),
      calc(100% - 150px) 100%,
      0 100%
    );
    /* 
      This shape effectively covers the entire screen except 
      a right-triangle in bottom-right corner of 150px side. 
    */
    overflow: hidden;
  }
  